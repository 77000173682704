<template>
  <div class="com-father">
    <div ref="threerate_ref" class="com-threerate">
    </div>
    <div v-if="!wulutype" class="fubit">可回收物准确率</div>
    <div v-if="!wulutype" class="fubit2">可回收物参与率</div>
    <div v-if="!wulutype" class="fubit3">厨余垃圾准确率</div>
    <div v-if="!wulutype" class="fubit4">厨余垃圾参与率</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetUserData } from '@/api/home'
import { steLoading } from '@/utils/loading'
import { getLocal } from '@/utils/local'

export default {
  data () {
    return {
      wulutype: false,
      chartInstance: null, // 初始化的对象
      allData: null, // 获取的数据
      standardsize: 1 // 全局标准值
    }
  },
  mounted () {
    // 获取缓存中的地区id不是中韩的话不显示五率
    var ids = getLocal('area_id')
    console.log(ids)
    // if (ids === '44535') {
    //   this.wulutype = true
    // }
    this.initChart() // 调用初始化
    // this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.threerate_ref, 'walden')
      // 对图表初始化配置控制
      const initOption = {
        color: ['#E97309', '#414e61'],
        //  提示框组件
        tooltip: {
          // 是否显示提示框组件，包括提示框浮层和 axisPointe
          show: false,
          // 触发类型: item:数据项触发，axis：坐标轴触发
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        // 系列列表。每个系列通过 type 决定自己的图表类型
        series: [
          {
            // 系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
            name: '任务进度',
            type: 'pie',
            // 饼图的半径，数组的第一项是内半径，第二项是外半径
            radius: ['60%', '80%'],
            // 是否启用防止标签重叠策略，默认开启
            avoidLabelOverlap: false,
            hoverAnimation: false,
            // 标签的视觉引导线样式，在 label 位置 设置为'outside'的时候会显示视觉引导线
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { // 数据值
                value: 20,
                // 数据项名称
                name: '分类知晓率',
                // 该数据项是否被选中
                selected: true,
                // 单个扇区的标签配置
                label: {
                  normal: {
                    // 是显示标签
                    show: true,
                    position: 'center',
                    fontSize: 12,
                    borderWidth: 0,
                    color: '#fff',
                    lineHeight: 25,
                    // 标签内容格式器，支持字符串模板和回调函数两种形式，字符串模板与回调函数返回的字符串均支持用 \n 换行
                    formatter: '{b}\n{d}%'
                  }

                }

              },
              {
                value: 100,
                label: {
                  normal: {
                    show: false

                  }
                }

              }

            ]
          },
          // 进度
          {
            type: 'gauge',
            name: '可回收物准确率',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: '60%',
            center: ['15%', '35%'],
            splitNumber: 5,
            itemStyle: {
              color: '#58D9F9',
              shadowColor: 'rgba(0,138,255,0.45)',
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            progress: {
              show: true,
              roundCap: true,
              width: 5
            },
            pointer: {
              show: false

            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 5
              }
            },
            axisTick: {
              length: 1,
              distance: 2,
              lineStyle: {
                color: '#58D9F9'
              }
            },
            splitLine: {
              length: 2,
              distance: 2,
              lineStyle: {
                width: 1,
                color: '#58D9F9'
              }
            },
            axisLabel: {
              show: false

            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '50%',
              lineHeight: 6,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 12,
              fontWeight: 'bolder',
              formatter: '{value} %',
              color: 'auto'
            },
            data: [
              {
                value: 50
              }
            ]
          },
          {
            type: 'gauge',
            name: '可回收物准确率',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: '60%',
            center: ['85%', '35%'],
            splitNumber: 5,
            itemStyle: {
              color: '#58D9F9',
              shadowColor: 'rgba(0,138,255,0.45)',
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            progress: {
              show: true,
              roundCap: true,
              width: 5
            },
            pointer: {
              show: false

            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 5
              }
            },
            axisTick: {
              length: 1,
              distance: 2,
              lineStyle: {
                color: '#58D9F9'
              }
            },
            splitLine: {
              length: 2,
              distance: 2,
              lineStyle: {
                width: 1,
                color: '#58D9F9'
              }
            },
            axisLabel: {
              show: false

            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '50%',
              lineHeight: 6,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 12,
              fontWeight: 'bolder',
              formatter: '{value} %',
              color: 'auto'
            },
            data: [
              {
                value: 50
              }
            ]
          },
          {
            type: 'gauge',
            name: '可回收物准确率',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: '60%',
            center: ['85%', '85%'],

            splitNumber: 5,
            itemStyle: {
              color: '#00F199',
              shadowColor: 'rgba(0,138,255,0.45)',
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            progress: {
              show: true,
              roundCap: true,
              width: 5
            },
            pointer: {
              show: false

            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 5
              }
            },
            axisTick: {
              length: 1,
              distance: 2,
              lineStyle: {
                color: '#00F199'
              }
            },
            splitLine: {
              length: 2,
              distance: 2,
              lineStyle: {
                width: 1,
                color: '#00F199'
              }
            },
            axisLabel: {
              show: false

            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '50%',
              lineHeight: 6,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 12,
              fontWeight: 'bolder',
              formatter: '{value} %',
              color: 'auto'
            },
            data: [
              {
                value: 50
              }
            ]
          },
          {
            type: 'gauge',
            name: '可回收物准确率',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: '60%',
            center: ['15%', '85%'],
            splitNumber: 5,
            itemStyle: {
              color: '#00F199',
              shadowColor: 'rgba(0,138,255,0.45)',
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            progress: {
              show: true,
              roundCap: true,
              width: 5
            },
            pointer: {
              show: false

            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 5
              }
            },
            axisTick: {
              length: 1,
              distance: 2,
              lineStyle: {
                color: '#00F199'
              }
            },
            splitLine: {
              length: 2,
              distance: 2,
              lineStyle: {
                width: 1,
                color: '#00F199'
              }
            },
            axisLabel: {
              show: false

            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '50%',
              lineHeight: 6,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 12,
              fontWeight: 'bolder',
              formatter: '{value} %',
              color: 'auto'
            },
            data: [
              {
                value: 50
              }
            ]
          }
        ]
      }
      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (parameter) {
      // 获取排名数据
      steLoading(this.chartInstance)
      const { data } = await GetUserData(parameter) // 换用户增长接口
      this.allData = data.data
      console.log('----')
      console.log(data)
      this.chartInstance.hideLoading()
      this.updataChart()
    },
    // 更新图表
    updataChart () {
      const DataOption = {

        series: [
          {
            // 系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
            name: '任务进度',
            type: 'pie',
            // 饼图的半径，数组的第一项是内半径，第二项是外半径
            radius: ['60%', '80%'],
            // 是否启用防止标签重叠策略，默认开启
            avoidLabelOverlap: false,
            hoverAnimation: false,
            // 标签的视觉引导线样式，在 label 位置 设置为'outside'的时候会显示视觉引导线
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { // 数据值
                value: this.allData.know_rate * 1,
                // 数据项名称
                name: '分类知晓率',
                // 该数据项是否被选中
                selected: true,
                // 单个扇区的标签配置
                label: {
                  normal: {
                    // 是显示标签
                    show: true,
                    position: 'center',
                    fontSize: 12,
                    borderWidth: 0,
                    color: '#fff',
                    lineHeight: 25,
                    // 标签内容格式器，支持字符串模板和回调函数两种形式，字符串模板与回调函数返回的字符串均支持用 \n 换行
                    formatter: '{b}\n{d}%'
                  }

                }

              },
              {
                value: 100 - (this.allData.know_rate * 1)
              }

            ]
          },
          {
            data: [
              {
                value: this.allData.kit_parting_rate
              }
            ]
          },
          {
            data: [
              {
                value: this.allData.kit_delivery_part
              }
            ]
          },
          {
            data: [
              {
                value: this.allData.kit_delivery_part
              }
            ]
          },
          {
            data: [
              {
                value: this.allData.kit_parting_rate
              }
            ]
          }
        ]
      }
      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.threerate_ref.offsetWidth / 335

      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped>
.com-father{
  position: relative;
}
.fubit2 ,
.fubit3 ,
.fubit4 , .fubit{
  font-size: 12px;
  position: absolute;
  color: white;
}
.fubit{
  top: 38%;
  right:3%;
}
.fubit2{
  top: 38%;
  left: 4%;
}
.fubit3{
  bottom:2%;
  right: 3%;
}
.fubit4{
  bottom: 2%;
  left: 4%;

}
.com-fiveclass{
  width: 100%;
  height: 100%;
}
</style>
