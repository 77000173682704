<template>
  <div class="com-father">
    <div ref="tan_ref" class="com-tan">
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetActivit, Getmoney, GetRecycle } from '@/api/home'
import { steLoading } from '@/utils/loading'
import { godou } from '@/utils/local'
import { GetTan } from '@/api/tan'
export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: [], // 获取的数据
      standardsize: 1 // 全局标准值
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    // this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.tan_ref, 'walden')
      this.chartInstance.clear()
      // 对图表初始化配置控制
      const initOption = {
        color: ['#00FFFF', '#ED721D','#fff'],
        grid: { // 坐标轴配置
          top: '26%',
          left: '3%',
          right: '6%',
          bottom: '20%',
          height: 280,

          containLabel: true // 包含文字

        },

        legend: {
          top: 30,
          itemGap: 40
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#b2b2b2'
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              width: 4
            }
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              width: 4
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#b2b2b2'],
              width: 1,
              type: 'dashed'
            }
          }
        }
      }

      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (type, parameter) {
      steLoading(this.chartInstance)
      this.allData = []
      // 获取环保金收支

      const { data } = await Getmoney(parameter)
      var setdara = data.data

      this.allData.push(setdara.x)
      var sta = []
      var s1 = {
        type: 'line',
        name: '总发放',
        color: '#26d9d9',
        data: setdara.y1
      }
      sta.push(s1)
      var s2 = {
        type: 'line',
        // name: '总兑换',
        name: '总兑换',
        color: '#ED721D',
        data: setdara.y2
      }
      sta.push(s2)
      var s3 ={
        name: '兑换',
        type: 'line',
        symbol: 'none', // 去掉折线上的小圆点
        data: setdara.y3
      }
      sta.push(s3)
      this.allData.push(sta)
      this.chartInstance.hideLoading()
      this.updataChart()
    },
    // 更新图表
    updataChart () {
      /* var shouwdata = [
        ['6-12', '6-13', '6-14', '6-15', '6-16', '6-17', '6-18', '6-19', '6-20', '6-21', '6-22'],
        [
          { type: 'bar', name: '乱七八遭', color: '#26d9d9', data: [5211, 8810, 1205, 3512, 1000, 2000, 1611, 9421, 3215, 8123, 9412] },
          { type: 'line', name: '可回收物', color: '#39b54a', data: [5211, 8810, 1205, 3512, 1000, 2000, 1611, 9421, 3215, 8123, 9412] },
        ]
      ] */
      var datas = []
      this.allData[1].forEach(item => {
        if (item.type === 'line') {
          datas.push(
            {
              data: item.data,
              name: item.name,
              type: 'line',
              symbol: 'none', // 去掉折线上的小圆点
              smooth: true,
              lineStyle: {
                color: item.color,
                width: 4
              }
            })
        }
        if (item.type === 'bar') {
          datas.push({
            name: item.name,
            data: item.data,
            type: 'bar',
            barMaxWidth: 10,
            itemStyle: {
              color: item.color // 柱的颜色
            }
          })
        }
      })
      const DataOption = {
        xAxis: {
          data: this.allData[0]
        },
        series: datas

        /* [

          /!*   {
            data: [1000, 2000, 5211, 3215, 8123, 8810, 1205, 1611, 9412, 9421, 3512]
          },
          {
            data: [8810, 1205, 1611, 9412, 9421, 3512, 1000, 2000, 5211, 3215, 8123]
          },
          {
            data: [9412, 9421, 3512, 1000, 8810, 1205, 1611, 2000, 5211, 3215, 8123]
          },
          {
            data: [9412, 9421, 3512, 1000, 2000, 5211, 8810, 1205, 1611, 3215, 8123]
          },
          {
            data: [3512, 1000, 2000, 5211, 8810, 1205, 1611, 9412, 9421, 3215, 8123]
          } *!/
        ] */

      }

      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.tan_ref.offsetWidth / 335
      const adapterOption = {
      }
      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped>

</style>
