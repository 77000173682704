<template>
  <div class="com-father">
    <div ref="Other_ref" class="com-other">
      <div @click="quliebiao" class="other-item">
        <span class="iconfont icon-fangzi01-copy iconfontbac"></span>
        <p class="item-texte">
          <span class="text-spanc span-jv">
        {{indexs === '开通社区'? allData.community_total_num : allData.user_total_num}}
          </span>
          {{ indexs }}
        </p>
      </div>
      <div @click="gohometan" class="other-item">
        <span class="iconfont icon-alarm_clock iconfontbac"></span>
        <p class="item-texte">
          <span class="text-spanc span-jv">
<!--             {{(allData.today_kitchen_count + allData.today_recycle_count)>0?allData.today_kitchen_count + allData.today_recycle_count:'0'}}-->
  {{allData.recycling_count}}
          </span>
          今日预约
        </p>
      </div>
      <div @click="gohometan" class="other-item">
        <span class="iconfont icon-ai49 iconfontbac"></span>
        <p class="item-texte">
          <span class="text-spanc span-jv">
            {{allData.recycling_finish}}
<!--               {{(allData.today_kitchen_weight + allData.today_recycle_weight)>0?parseInt(allData.today_kitchen_weight + allData.today_recycle_weight):'0'}}-->
          </span>
          今日回收
        </p>
      </div>

      <div class="other-item">
        <span class="iconfont icon-rest-fill span-jvd"></span>
        <p class="item-texte">
          <span class="text-spanc span-jv">
             {{ bucketsData.former }}
          </span>
          原桶点数
        </p>
      </div>
      <div class="other-item">
        <span class="iconfont icon-rest-fill span-slan"></span>
        <p class="item-texte">
          <span class="text-spanc span-slan">
             {{ bucketsData.now>0? bucketsData.now:0}}
          </span>
          现桶点数
        </p>
      </div>
      <div class="other-item">
        <span class="iconfont icon-rest-fill span-lan"></span>
        <p class="item-texte">
          <span class="text-spanc span-lan">
<!--             {{ bucketsData.cacnel }}-->
             {{(bucketsData.former - bucketsData.now)>0?bucketsData.former - bucketsData.now :'0'}}

          </span>
          撤桶点数
        </p>
      </div>

    </div>

  </div>
</template>

<script>
import { Getbuckets, Getcominit, GetStatistical } from '@/api/home'

export default {
  data () {
    return {
      bucketsData: {}, // 桶点数
      allData: {}, // 获取的数据
      standardsize: 1, // 全局标准值
      indexs: '开通社区' // 判断是不是小区
    }
  },
  mounted () {
    // this.getData() // 获取服务器数据
  },
  activated () {
    this.getData() // 获取服务器数据
  },
  methods: {

    // 获取服务器数据
    async getData (parameter, name) {
      // 获取排名数据
      if (name === '小区') {
        this.allData = []
        this.indexs = '参与人数'
      } else {
        this.allData = []
        this.indexs = '开通社区'
      }
      const { data } = await GetStatistical(parameter)
      const { data: res } = await Getbuckets(parameter)
      this.allData = data.data
      this.bucketsData = res.data
    },
    quliebiao () {
      this.$router.push({
        name: 'Communitydetails'

      })
    },
    gohometan () {
      this.$emit('gototan')
    }

  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/index.less';
@import '~@/assets/css/fontface.css';
.com-other{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  align-items: center;
  width: 100%;
  .other-item{
    position: relative;
    background: rgba(0,0,0,0.35);
    padding: 6px 1px;
    width: 110px;
    height: 38px;
    margin-top: 22px;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .item-texte{
      display: flex;
      font-size: 12px;
      align-content: center;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: flex-end;
      color: @text-color;
      font-weight: bold;
      line-height: 1;
      .text-spanc{
        font-size: 19px;

        color: @major-color;
      }

    }
    .iconfont{
      color: rgba(255,255,255,0.7);
     font-size: 25px;

      width: 35px;
      height: 35px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .iconfontbac{
      background: linear-gradient(to top, #29ABE2 0%,#00FFFF 100%);
      border-radius: 3px;
      color: #fff;
    }
   /* 阴影斜面
   &:nth-child(1)::after , &:nth-child(2)::after , &:nth-child(3)::after{
      content: '';
      position: absolute;
      width: 32%;
      left: 0;
      border-top: 65px solid #ffffff26;
      border-right: 35px solid transparent;

    }*/
  }
  .other-item:nth-child(2), .other-item:nth-child(5){
    margin: 22px 14px 0 14px;
  }
  .other-item:nth-child(6), .other-item:nth-child(4), .other-item:nth-child(5){
    margin-top: 15px;
  }
}
.span-lan{
  color: @bit-color !important;
}
.span-slan{
  color: #29ABE2 !important;
}
.span-jvd{
  color: #ED721D !important;
}
</style>
