<template>
  <div class="com-father">
    <div class="home-cntent" id="nidaye">
<!--      弹窗-->
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        :modal="false"
        :before-close="handleClose">
        <img src="../assets/img/tan.svg" alt="">
        <div class="title-date">
        <el-date-picker v-if="tantype!==5"
            v-model="tandata"
            type="daterange"
            @change="tanDateFun"
            :default-time="['00:00:00','23:59:59']"
            range-separator="⇋"
            :disabled="tandatetype"
            value-format="timestamp"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
            <i slot="suffix" style="display: none" class="el-input__icon el-icon-date"></i>
          </el-date-picker>
          <div v-if="tantype===5" class="nianxuan">
            <el-date-picker
              v-model="tandatanian"
              @change="tanDatenianFun"
              type="year"
              value-format="yyyy"
              :clearable="false"
              placeholder="选择年">
            </el-date-picker>
          </div>

        </div>
        <div class="title-bit">

          <span  @click="tantype===5?titelhgetfun('类别'):null" :class="tantype===5?'weizhong':''">{{ tanname }}</span>
          <span :class="tantype!==5?'weizhong':''" style="margin-left: 7px;" @click="titelhgetfun('同比')" v-if="tanname==='分类类别'">同比环比</span>
        </div>
        <div class="tan-centom">
          <tanexhibitiono v-if="tantype===0" ref="tan_ref"></tanexhibitiono>
          <tanexhibition v-else-if="tantype===1" ref="tan_ref1"></tanexhibition>
          <tanexhibitiont v-else-if="tantype===2" ref="tan_ref2"></tanexhibitiont>
          <tanexhibitionth v-else-if="tantype===3" ref="tan_ref3"></tanexhibitionth>
          <tanexhibitionf  v-else-if="tantype===4" ref="tan_ref4"></tanexhibitionf>
          <tanexhibitionbi  v-else-if="tantype===5" ref="tan_ref5"></tanexhibitionbi>

        </div>

      </el-dialog>
      <transition name="el-fade-in-linear">
      <div class="xuanfu" v-show="!dialogVisible">
        <div class="xuanfu-item"><a href="#/communitydetails">小区列表</a></div>
        <div class="xuanfu-item"><a href="#/rankingdetails">小区排行</a></div>
        <div class="xuanfu-item"><a href="#/assessment">考核规范</a></div>
      </div>
      </transition>
      <transition name="el-fade-in-linear">
      <div class="title-date" v-show="!dialogVisible">
        <el-date-picker
          v-model="lookdata"
          type="daterange"
          @change="listDateFun"
          range-separator="⇋"
          :default-time="['00:00:00','23:59:59']"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
          <i @click="chachongzhi" slot="suffix" style="display: none" class="el-input__icon el-icon-date"></i>
        </el-date-picker>
      </div>
      </transition>
          <MapDrag ref="mep_ref" @onreChange="gun"></MapDrag>
<!--      头部标题-->
      <Hiders></Hiders>
<!--      图表区域-->
      <div class="home-echers">
<!--        左边部分-->
        <div class="echers-left">
<!--          开通社区-->
          <div class="echers-other"> <Other ref="other_ref" @gototan="yuetan"></Other></div>
          <!--          回收量-->

          <div class="echers-recoveryvolume">
            <RecoveryVolume @update="item=>this.recoverdata=item" ref="RecoveryVolume_ref"></RecoveryVolume>
            <!--点击的按钮-->
<!--            <div class="nav-instructions">
              <el-button @click="titelhgetfun('回收量',item.concet)" :style="{background:(item.liang?'#29ABE2':'')}" v-for="item in hunbaojinbutton" :key="item.index" type="warning">{{ item.name }}</el-button>
            </div>-->
            <div class="nav-instructions">
              <el-button @click="titelhgetfun('回收量')" type="warning" icon="el-icon-more"></el-button>
            </div>
            <div class="bottomText">
              <p @click="details()">回收重量：<span>{{recoverdata.rec_total + recoverdata.kit_total}}Kg</span></p>
              <p>预约量：<span class="lan">{{recoverdata.rec_count + recoverdata.kit_count}}</span>单</p>

            </div>
          </div>
          <!--          分类类别-->
          <div class="echers-classification">
            <Classification ref="recycleclass_ref"></Classification>
            <div class="nav-instructions">
              <el-button @click="titelhgetfun('类别')" type="warning" icon="el-icon-more"></el-button>
            </div>
          </div>
<!--          排名-->
          <div class="echers-rankin">
            <Ranking ref="ranking_ref"></Ranking>
            <div class="nav-instructions">
              <el-button @click="titelhgetfun('排名')" type="warning" icon="el-icon-more"></el-button>
            </div>
          </div>
        </div>

        <!--        右边部分-->
        <div class="echers-right">
<!--          三率-->
          <div class="echers-threerate" >
            <Threerate ref="threerate_ref"></Threerate>
<!--            <fiecclass  v-show="!wulutype"  ref="foveerate_ref"></fiecclass>-->
          </div>

<!--          <div class="echers-threerate">-->
<!--            <fiecclass  v-show="!wulutype" ref="threerate_ref"></fiecclass>-->
<!--          </div>-->
<!--          环保金收支-->
          <div class="echers-expenditure">
            <Expenditure ref="Expenditure_ref" @updata="item=>this.Expendituredata=item"></Expenditure>
<!--            <div class="nav-instructions">
              <el-button @click="titelhgetfun('环保金',item.concet)" :style="{background:(item.back?'#29ABE2':'')}" v-for="item in hunbaojinbutton" :key="item.index" type="warning">{{ item.name }}</el-button>
              </div>-->
            <div class="nav-instructions">
              <el-button @click="titelhgetfun('环保金')" type="warning" icon="el-icon-more"></el-button>
            </div>
            <div class="bottomText">
              <p>总发放：<span class="lan">{{Expendituredata.puton}}元</span></p>
              <p>总兑换：<span>{{Expendituredata.consume}}元</span></p>
              <p>积分兑换：<span>{{Expendituredata.exchange}}元</span></p>

            </div>
          </div>
<!--          活跃度-->
          <div class="echers-usergrowth">
            <Usergrowth ref="usergrowth_ref" @updata="item=>this.useredata=item"></Usergrowth>
<!--            <div class="nav-instructions">-->
<!--              <el-button @click="titelhgetfun('活跃度',item.concet)" :style="{background:(item.huoyue?'#29ABE2':'')}" v-for="item in hunbaojinbutton" :key="item.index" type="warning">{{ item.name }}</el-button>-->
<!--            </div>-->
            <div class="nav-instructions">
              <el-button @click="titelhgetfun('活跃度')" type="warning" icon="el-icon-more"></el-button>
            </div>
            <div class="bottomText">
              <p>新增：<span class="lan">{{useredata.y_active_rate}}人</span></p>
              <p>预约：<span>{{useredata.y_recycle_rate}}人</span></p>
              <p>签到：<span style="color: white">{{useredata.y_score_rate}}人</span></p>
            </div>
          </div>
<!--          // 订单-->
          <div class="echers-realtimeorder">
            <Realtimeorder ref="realtimeorder_ref"></Realtimeorder>
<!--            <div class="nav-instructions">
              <el-button @click="titelhgetfun('订单')" :style="{background:(item.back?'#29ABE2':'')}" v-for="item in shishiabutton" :key="item.index" type="warning">{{ item.name }}</el-button>
            </div>-->
            <div class="nav-instructions">
              <el-button @click="titelhgetfun('订单')" type="warning" icon="el-icon-more"></el-button>
            </div>
          </div>
        </div>
      </div>
      <transition name="el-fade-in-linear">
      <div  v-show="!dialogVisible" class="Communityinformation">
        <dv-border-box-9 backgroundColor="#7F7F7F99">
          <h3>{{communityData.name}}</h3>
          <div class="gang"></div>
          <p>物业：<span>{{communityData.property}}</span></p>
          <p>督导员：<span>{{communityData.supervisor}}</span></p>
          <p>物业负责人：<span>{{communityData.property_person}}</span></p>
          <p>回收员：<span>{{communityData.recycler_name}}</span></p>
          <p>收运时间：<span>{{communityData.kitchen_waste_collection_time}}-{{communityData.collection_time}}</span></p>
          <p>入住率：<span>{{communityData.occupancy_rate.substring(0,communityData.occupancy_rate.indexOf('.'))}}%</span></p>
          <p>党员：<span>{{communityData.party_member === ''?'0':communityData.party_member}}人</span></p>
          <p>注册户数：<span>{{communityData.register_house === ''?'0':communityData.register_house}}户</span></p>
          <p>注册人数：<span>{{communityData.register_number === ''?'0':communityData.register_number}}人</span></p>
          <div class="commun-item">
            <div class="icond">
              <span class="iconfont icon-kehu"></span>
              <p>总户数<span>{{communityData.total_num_house}}</span></p>
            </div>
            <div class="icond">
              <span class="iconfont icon-kehu"></span>
              <p>实际户数<span>{{communityData.actual_num_house}}</span></p>
            </div>

          </div>
        </dv-border-box-9>
      </div>
      </transition>
      <!--      图表区域结束-->
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import MapDrag from '@/components/MapDrag'
// 引入各个接口
import { Getcominit, GetRecycleClass } from '@/api/home' // 分类类别

// 引入各个板块组件
import Other from '@/components/Other'
import RecoveryVolume from '@/components/RecoveryVolume'
import Classification from '@/components/Classification'
import Ranking from '@/components/Ranking'
import Threerate from '@/components/Threerate'
import Expenditure from '@/components/Expenditure'
import Usergrowth from '@/components/Usergrowth'
import Realtimeorder from '@/components/Realtimeorder'
import Hiders from '@/components/Hiders'
import tanexhibition from '@/components/TanExhibition'
import tanexhibitiono from '@/components/TanExhibition1'
import tanexhibitiont from '@/components/TanExhibition2'
import tanexhibitionth from '@/components/TanExhibition3'
import tanexhibitionf from '@/components/TanExhibition4'
import tanexhibitionbi from '@/components/TanExhibitionbi'
// 新分类参与率
import fiecclass from '@/components/Fiveclass'
import { getLocal } from '@/utils/local'
export default {
  inject: ['reload'],
  data () {
    return {
      wulutype: false,
      residentialid: { area_id: '44534', genra: '9' },
      funname: '主',
      communityData: {
        occupancy_rate: ''
      },
      shishiabutton: [
        { type: '9', name: '全部', back: true },
        { type: '1', name: '待回收', back: false },
        { type: '2', name: '待分拣', back: false }
      ],
      hunbaojinbutton: [
        { name: '近一周', concet: 'is_week', concettow: 'week', back: true, liang: true, huoyue: true },
        { name: '近一月', concet: 'is_month', concettow: 'month', back: false, liang: false, huoyue: false },
        { name: '近一年', concet: 'is_year', concettow: 'year', back: false, liang: false, huoyue: false }
      ],
      recoverdata: { consume: 0, puton: 0 },
      useredata: {}, // 活跃度传值
      tandatetype: false, // 弹窗时间选择状态
      Expendituredata: {},
      homeZoom: 14, // 地图级别
      cycle: 0, // 周期
      rate: 1,
      lookdata: null,
      lookdataobj: {},
      dialogVisible: false, // 弹窗控制值
      tantype: 0, // 弹窗的属性
      tanname: '', // 弹窗名
      tandata: null, // 弹窗时间
      tandatanian: null, // 弹窗时间年
      lookid: {} // 查询小区id
    }
  },
  components: {
    MapDrag,
    Other,
    RecoveryVolume,
    Classification,
    Ranking,
    Threerate,
    Expenditure,
    Usergrowth,
    Realtimeorder,
    tanexhibition,
    tanexhibitiono,
    tanexhibitiont,
    tanexhibitionth,
    tanexhibitionf,
    tanexhibitionbi,
    Hiders,
    fiecclass
  },
  mounted () {
    this.resize_window()
    window.addEventListener('resize', () => {
      this.resize_window()
    })
    this.reload()
    // this.gun()
    // 详情跳转
    this.details()
  },
  updated () {
    // 详情跳转
    // this.details()
  },
  destroyed () {
    window.removeEventListener('resize', () => {
      this.resize_window()
    })
  },

  methods: {
    // 关闭弹窗
    handleClose (done) {
      done()
    },
    // 时间选中的方法
    listDateFun (e) {
      if (e !== null) {
        // console.log(e)
        this.lookdataobj.time_start = e[0] / 1000
        this.lookdataobj.time_end = e[1] / 1000
        // 发起请求
        this.gun(this.lookdataobj, '时间')
      } else {
        this.lookdataobj.time_start = null
        this.lookdataobj.time_end = null
        this.gun()
      }
    },
    // 重置时间
    chachongzhi () {
      // console.log(15)
    },
    // 弹窗时间选中的方法
    tanDateFun (e) {
      this.gettan(this.tantype, { time_start: e[0] / 1000, time_end: e[1] / 1000 })
    },
    tanDatenianFun () {
      this.gettan(this.tantype, {
        year: this.tandatanian
      })
    },
    // 屏幕适配
    resize_window () {
      // console.log('haha')
      const baseWidth = document.documentElement.clientWidth
      const baseHeight = document.documentElement.clientHeight

      const appStyle = document.getElementById('app').style
      const realRatio = baseWidth / baseHeight
      const designRatio = 16 / 9
      let scaleRate = baseWidth / 1920
      if (realRatio > designRatio) {
        scaleRate = baseHeight / 1080
      }
      /* if (baseWidth < 1920) {
        appStyle.transformOrigin = 'left top'
        appStyle.transform = `scale(${scaleRate})`
        appStyle.width = `${baseWidth / scaleRate}px`
      }else {

      } */
      appStyle.transformOrigin = 'left top'
      appStyle.transform = `scale(${scaleRate})`
      appStyle.width = `${baseWidth / scaleRate}px`
    },

    // 判断是不是来自详情页的

    // 通过获取传过来的中心点进行复制
    details () {
      var routeid = this.$route.query
      // console.log(routeid)
      if (JSON.stringify(routeid) !== '{}') {
        this.homedetasearch(routeid, '初始')
      } else {
        // 判断如果是没有参数，直接调用所有数据
        this.gun()
      }
    },
    /**/
    // 搜索事假
    homedetasearch (e, name) {
      var numid = { community_id: e.id }

      this.gun(numid, '小区')
      if (name === '初始') {
        // 如果是是初始携带参数的话执行地图操作
        this.searchfun(e, name)

        this.getcommunityinf(numid) // 调用获取小区详情
      } else {
        this.searchfun(e)// 地图显示数据
        this.getcommunityinf(numid, '搜索') // 调用获取小区详情
      }
    },
    searchfun (parameter, t) { // 搜索直接显示小区
      this.$refs.mep_ref.searchlook(parameter, t)
    },
    gettan (type, parameter) {
      switch (type) {
        case 0:
          this.$nextTick(() => {
            this.$refs.tan_ref.getData(type, parameter)
          })
          break
        case 1:
          this.$nextTick(() => {
            this.$refs.tan_ref1.getData(type, parameter)
          })
          break
        case 2:
          this.$nextTick(() => {
            this.$refs.tan_ref2.getData(type, parameter)
          })
          break
        case 3:
          this.$nextTick(() => {
            this.$refs.tan_ref3.getData(type, parameter)
          })
          break
        case 4:
          this.$nextTick(() => {
            // console.log(parameter)
            this.$refs.tan_ref4.getData(type, parameter)
          })
          break
        case 5:
          this.$nextTick(() => {
            // console.log(parameter)
            this.$refs.tan_ref5.getData(parameter)
          })
          break
      }
    },
    // 桶点信息
    gempe (parameter) {
      this.$refs.mep_ref.tongdian(parameter)
    },
    // 开通社区
    getother (parameter, name) {
      this.$refs.other_ref.getData(parameter, name)
    },
    // 分类类别
    getrecycleclass (parameter) {
      this.$refs.recycleclass_ref.getData(parameter)
    },
    // 回收量
    getrecoveryvolume (parameter) {
      this.$refs.RecoveryVolume_ref.getData(parameter)
    },
    // 排名
    getranking (parameter, name) {
      this.$refs.ranking_ref.getData(parameter, name)
    },
    // ------右----------
    // 三率
    getthreerate (parameter) {
      this.$refs.threerate_ref.getData(parameter)
      // this.$refs.foveerate_ref.getData(parameter)
    },
    // 环保金收支
    getexpenditure (parameter) {
      this.$refs.Expenditure_ref.getData(parameter)
    },
    // 活跃度
    getusergrowth (parameter) {
      this.$refs.usergrowth_ref.getData(parameter)
    },
    // 订单
    getrealtimeorder (parameter) {
      this.$refs.realtimeorder_ref.getData(parameter)
    },
    // 小区详情
    async getcommunityinf (parameter, name) {
      // console.log(parameter)
      const { data } = await Getcominit(parameter)
      this.communityData = data.data.list
      this.gempe(data.data)

      console.log(data)
    },

    // 点击查询的方法name是板块，data是数据
    titelhgetfun (name, data, indexs) {
      this.tandata = []
      switch (name) {
        case '回收量':
          this.tanname = '减量统计'
          this.tandatetype = false
          this.dialogVisible = true
          this.tantype = 0
          this.gettan(this.tantype, {
            ...this.lookid, ...this.lookdataobj
          })

          break
        case '类别':

          this.tanname = '分类类别'
          this.dialogVisible = true
          this.tandatetype = false
          this.tantype = 1

          this.gettan(this.tantype, {
            ...this.lookid, ...this.lookdataobj
          })
          break
        case '排名':

          this.$router.push({
            name: 'Rankingdetails'

          })
          break
        case '环保金':
          this.tanname = '环保金收支'
          this.dialogVisible = true
          this.tandatetype = false
          this.tantype = 2
          this.gettan(this.tantype, {
            ...this.lookid, ...this.lookdataobj
          })
          break
        case '活跃度':
          this.tanname = '增长活跃度'
          this.dialogVisible = true
          this.tandatetype = false
          this.tantype = 3
          this.gettan(this.tantype, {
            ...this.lookid, ...this.lookdataobj
          })
          break
        case '订单':
          this.tanname = '实时订单'
          this.dialogVisible = true
          this.tandatetype = false
          this.tantype = 4
          this.gettan(this.tantype, this.lookid)

          break
        case '同比':
          this.dialogVisible = true
          this.tandatetype = false
          this.tantype = 5
          this.gettan(this.tantype, {
            year: this.tandatanian
          })

          break
      }
    },
    // 点击后打开实时订单弹窗
    yuetan () {
      this.titelhgetfun('订单')
    },
    // 地图联动时的事件
    gun (nume, name) {
      // 通过name传参是时间的话，把他们合并
      if (name === '时间') {
      } else {
        this.lookid = nume
      }
      var numes = this.lookdataobj

      if (nume !== undefined && nume.community_id !== undefined) {
        numes.community_id = nume.community_id
      }
      if (name === '去详情') { /* 缩小时去掉详情框 */
        $('.Communityinformation').animate({ bottom: '-40%' }, 1000)
      }
      // 回收量
      this.getrecoveryvolume(numes)
      // 分类类别
      this.getrecycleclass(numes)
      // 判断是不是在小区选择的时间选择
      if (this.lookdataobj.community_id !== undefined) {
        // 排名
        this.getranking(numes, '小区')
        // 开通社区
        this.getother(numes, '小区')
      } else {
        // 排名
        this.getranking(numes, name)
        // 开通社区
        this.getother(numes, name)
      }

      // 三率
      this.getthreerate(numes)
      // 环保金收支
      this.getexpenditure(numes)
      // 活跃度
      this.getusergrowth(numes)
      // 订单
      this.residentialid = numes
      this.getrealtimeorder(numes)

      if (name === '小区') {
        this.lookdataobj.community_id = nume.community_id

        this.communityData = {
          occupancy_rate: ''
        }
        this.getcommunityinf(numes)
        $('.Communityinformation').animate({ bottom: '6%' }, 3000)
      } else {
        delete this.lookdataobj.community_id
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/index.less';
.com-father{
  .home-cntent{
    //变了
    width:100% !important;
    height: 100% !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .xuanfu{
      position: absolute;
      z-index: 9;
      display: flex;
      top: 12%;
      left: 26%;
      .xuanfu-item{
        background: #00FFFF;
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: #006590;
        opacity: 1;
        border-radius: 24px;
        padding: 2px 10px;
        border: 3px solid #1FA3CB;
        a{
          color: #006590;
          text-decoration: none;
        }
        &:nth-child(2){
          margin: 0 10px;
        }
      }
    }
.m-map{
  position: absolute;
  z-index: 1;
}
    .home-hider{
      width: 100%;
      height: 120px;
      z-index: 3;
      display: flex;
      box-shadow: 0px 154px 154px 25px #052957;
      background: url(../assets/img/back.png) no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      .home-hider-number, .home-hider-days{ // 当前参与分类用户数/运行天数
        flex: 1;
        text-align: center;
        font-size: 18px;
        color: @text-color;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
        z-index: 2;
        p{
          margin: 0;
          letter-spacing: 1.2px;
          font-size: @fontsize;
          font-weight: bold;
          margin-right: 16%;
          span{
            color: @major-color;
            font-size: @spanfontsize;
          }
        }
        .one-svg{
          display: flex;
          justify-content: flex-end;
          img{
            width: 34%;
          }
        }
      }
      .home-hider-title{  //标题
        flex: 6;

        color: @text-color;
        position: relative;
        h1{
          text-align: center;
          font-size: 46px;
          margin-top: 52px;
          margin-bottom: 17px;

        }
        .title-svg{
          position: absolute;
          bottom: 0;
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
        }
        .dv-decoration-3{
          position: absolute;
          bottom: 9%;
        }
        .decoration3s{
          right: 0;
        }
      }
      .home-hider-days{ //运行天数
        flex: 1;

      }

    }
    .home-echers{
      width: 100%;
      height: 100%;

      position: relative;
      .echers-left , .echers-right{
        width: @show-whith;
        //height: 1080-126px;
        background: #00000073;
        position: absolute;
        top: 0;
        border-top: 3px solid #ffffff4d;
        z-index: 4;
      }
      .echers-left{
        left: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: space-around;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        .echers-other , .echers-recoveryvolume, .echers-classification, .echers-rankin{
          position: relative;
          width: 401px;
          margin-top: 18px;
          border:1px solid #fff;
          padding: 10px;

        }
        .echers-recoveryvolume{
          margin-top: 10px;
        }
        .echers-recoveryvolume, .echers-classification, .echers-rankin{
          height: 204px;
          background: url("../assets/img/dakuang.svg") no-repeat;
          border: 0;
          background-size: 100% 100%;
          background-position: center center;
        }
        .echers-other{
          height: 161px;
          margin-top: 34px;
          background: url("../assets/img/xiaokuang.svg") no-repeat;
          border: 0;
          background-size: 100% 100%;
          background-position: center center;

        }

        .echers-classification{}
        .echers-rankin{
          margin-bottom: 41px;
        }
        &:before{
          content: '';
          height: 100%;
          position: absolute;
          left: 0;
          z-index: 2;
          box-shadow:0px 37px 178px 30px #052957;
        }

      }
      .echers-right{
        right: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: space-around;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        .echers-threerate, .echers-expenditure, .echers-usergrowth, .echers-realtimeorder{
          width: 401px;
          margin-top: 18px;
          border:1px solid #fff;
          padding: 10px;
          position: relative;
        }
        .echers-expenditure, .echers-usergrowth, .echers-realtimeorder{
          height: 204px;
          background: url("../assets/img/dakuang.svg") no-repeat;
          border: 0;
          background-size: 100% 100%;
          background-position: center center;
        }
        .echers-threerate{
          height: 161px;
          margin-top: 34px;
          background: url("../assets/img/xiaokuang.svg") no-repeat;
          border: 0;
          background-size: 100% 100%;
          background-position: center center;
        }
        .echers-expenditure{
          margin-top: 10px;
        }
        .echers-usergrowth{}
        .echers-realtimeorder{
          margin-bottom: 41px;
        }
        &:before{
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          z-index: 2;
          box-shadow:0px 37px 178px 30px #052957;
        }
      }
      &:before{
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        box-shadow:0px 37px 178px 30px #052957;
      }
    }
    ::v-deep .el-dialog{
      width: 955px;
      height: 434px;
      z-index: 9999;
      background: rgba(0,0,0,0.5);
      .el-dialog__header{
        padding: 0;
        .el-dialog__headerbtn {
          top: 14px;
          right: 21px;

        }
        .el-dialog__close{
          color: #006590;
          font-size: 22px;
          line-height: 14px;
          font-weight: bold;
        }
      }
      .el-dialog__body{
        padding: 0;
      }
      .diakuang{
        width: 180px;
        height: 33px;
        background: cyan;
        border-radius: 1px;
        position: absolute;
        &::before, &::after{
          content: '';
          position: absolute;
          height: 13px;

        }
        &::before{
          border-left: 10px solid cyan;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          right: -10px;
        }
        &::after{
          border-right: 10px solid cyan;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          left: -10px;
        }
      }
      .title-date{
        right: 8%;
        top: 5px;
        .el-date-editor{
          width: auto;
          height: 32px;
          .el-range-input{
            width: 65px;
            height: 16px;
            color: white;
            background: #006590 !important;
            border: 2px solid #006590;
            &::-webkit-input-placeholder {
              color: white;
            }

          }

          .el-range__close-icon{
            display: none;
          }
          .el-range-separator{
            line-height: 24px;
            color: #006590 !important;
          }
        }
      }
      .title-bit{
        position: absolute;
        left: 2%;
        top: 6px;
        color: #006590;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        .weizhong{
          background: white;
          padding: 0px 5px;
          font-size: 14px;
          border-radius: 4px;
          cursor:pointer;
        }
      }
      .tan-centom{
        width: 956px;
        height: 380.6px;
        position: absolute;
        top: 6%;
        left: 0;
      }
      .com-tan{
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav-instructions{
    position: absolute;
    right: 2%;
    top: 2%;
    width: 69px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    button{
      border-radius: 0;
      font-size: 30px;
      color: #00FFFF;
      border: 0;
      background: content-box;
    }
  }
  .Communityinformation{
    position: absolute;
    right: 26%;
    bottom: -40%;
    width: 260px;
    height: 400px;
    z-index: 9999;
    .dv-border-box-9{
      h3{
        font-size: 22px;
        text-align: center;
        color: white;
        margin: 0;
       padding: 10px 0 10px 0;
      }
      .gang{
        width: 80%;
        margin: 0 auto;
        height: 2px;
        background: #999999;
      }
      .commun-item{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0 22px;
        .icond{
          width: 100px;
          height: 55px;
          border: 2px solid #FFFFFF;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .icon-kehu{
            font-size: 36px;
            color: white;
          }
          p{
            margin: 0;
            display: flex;
            flex-direction: column;
            padding: 0;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-end;
            font-size: 12px;
            span{
              color: @major-color;
              font-size: 20px;
            }
          }
        }
      }
      p{
        color: white;
        font-size: 14px;
        padding-left: 20px;
        font-weight: bold;
        margin: 9px 0;
        span{
          color: @bit-color;
        }
      }
    }

  }
}
.title-date{
  position: absolute;
  top: 12%;
  right: 26%;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  z-index: 9;

  .el-date-editor {
    background: center;
    border: 0;
    width: 190px;
    ::v-deep .el-range-input{
      background: #0000000d !important;
      color: #00FFFF;
      border: 2px solid #1fa3cb;
      border-radius: 3px;
      height: 30px;
      font-size: 12px;
      width: 92px;
    }
    ::v-deep .el-range-separator{
      color: white !important;
    }
    ::v-deep .el-range__icon{
      margin-right: 8px;
      display: none;
    }
  }
}
.nianxuan{
  ::v-deep  .el-input__inner{
    width: 153px;
    height: 21px;
    color: white;
    background: #006590 !important;
    border: 2px solid #006590;
  }
  ::v-deep .el-icon-date{
    line-height: 33px;
  }
  ::v-deep .el-date-editor{
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

</style>
