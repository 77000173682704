<template>
  <div class="com-father">
    <div ref="expenditure_ref" class="com-expenditure">
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { Getmoney } from '@/api/home'
import { steLoading } from '@/utils/loading'
import { godou } from '@/utils/local'
export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: null, // 获取的数据
      standardsize: 1 // 全局标准值
    }
  },
  mounted () {
    this.initChart() // 调用初始化
    this.getData() // 获取服务器数据
    window.addEventListener('resize', this.screenAdapter)
    // 屏幕适配
    this.screenAdapter()
  },
  destroyed () {
    // 移除监听
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    // 初始化
    initChart () {
      this.chartInstance = echarts.init(this.$refs.expenditure_ref, 'walden')
      // 对图表初始化配置控制
      const initOption = {
        color: ['#00FFFF', '#ED721D','#fff'],
        title: { // 标题设置
          text: '环保金收支',
          textStyle: {
            fontWeight: 'bold'
          },
          left: '3%',
          top: '3%'
        },
        grid: { // 坐标轴配置
          top: '26%',
          left: '3%',
          right: '6%',
          bottom: '20%',
          containLabel: true // 包含文字

        },

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',

          splitLine: {
            show: true,
            lineStyle: {
              color: ['#3A4755'],
              width: 1,
              type: 'dotted'
            }
          }
        },
        series: [{
          name: '总发放',

          symbol: 'none', // 去掉折线上的小圆点
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#00FFFF'

          }
        }, {
          // name: '总兑换',
          name: '总兑换',
          type: 'line',
          symbol: 'none', // 去掉折线上的小圆点
          smooth: true,
          lineStyle: {
            color: '#ED721D'

          }
        },
          {
            // name: '总兑换',
            name: '兑换',
            type: 'line',
            symbol: 'none', // 去掉折线上的小圆点
            smooth: true,
            lineStyle: {
              color: '#fff'

            }
          }]
      }
      this.chartInstance.setOption(initOption)
    },
    // 获取服务器数据
    async getData (parameter) {
      steLoading(this.chartInstance)
      // 获取排名数据
      const { data } = await Getmoney(parameter)

      this.allData = data.data
      this.allData.consume = godou(this.allData.consume)
      this.allData.puton = godou(this.allData.puton)
      this.$emit('updata', this.allData)
      this.chartInstance.hideLoading()
      this.updataChart()
    },
    // 更新图表
    updataChart () {
      const DataOption = {
        xAxis: {
          data: this.allData.x
        },
        series: [{
          data: this.allData.y1
        }, {
          data: this.allData.y2
        },
          {
            data: this.allData.y3
          }
        ]

      }
      this.chartInstance.setOption(DataOption)
    },
    // 屏幕适配
    screenAdapter () {
      this.standardsize = this.$refs.expenditure_ref.offsetWidth / 335
      const adapterOption = {
        title: { // 标题设置
          textStyle: {
            fontSize: 16 * this.standardsize
          }
        }
      }
      this.chartInstance.setOption(adapterOption)
      // 手动调用图表对象resize
      this.chartInstance.resize()
    }
  }
}
</script>

<style scoped>

</style>
