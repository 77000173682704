<template>
  <div class="com-father">
    <div class="home-cntent">
      <!--      头部标题-->
      <Hiders></Hiders>
      <!--      图表区域-->
      <div class="community-con">
        <div class="gohome"><span class="iconfont icon-fangzi01-copy iconfontbac"></span><a href="/">返回首页</a></div>

        <div class="ranking_item">
          <img src="../assets/img/radakuang.svg" alt="">
          <div class="item-hide">
            <p>知晓排名</p>
            <p>
              <span @click="qiehuan(1)" :style="{'color':(oneclass===1?'#00ffff':'')}" class="lvclis chushi">注册户</span>
              <span @click="qiehuan(2)" :style="{'color':(oneclass===2?'#00ffff':'')}" class="lvclis">注册率</span>
            </p>
          </div>
          <div class="item-content">
           <dv-loading v-if="loa1"></dv-loading>
            <div v-for="(item,i) in allData" :key="i" class="v1">
              <p class="p-index"><span class="iconfont icon-dunpai2"><span>{{i+1}}</span></span></p>
              <p class="p-text">{{ item.name }}</p>
              <p class="p-ex"><el-progress :show-text="false" :stroke-width="12" :percentage="parseFloat(item.baifen)"></el-progress></p>
              <p class="p-num">{{ oneclass===1?item.actual_num_house:item.baifen }}</p>
            </div>
          </div>
        </div>
        <div class="ranking_item">
          <div class="date_item">
            <img class="dateimg" src="../assets/img/xuanzeq.png" alt="">
            <el-date-picker
              v-model="datevalue0"
              size="mini"
              @change="canpaifun"
              value-format="timestamp"
              type="daterange"
              range-separator="~"
              start-placeholder="开始"
              end-placeholder="结束"
              :picker-options="pickerOptions"
              :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <img src="../assets/img/radakuang.svg" alt="">
          <div class="item-hide">
            <p>参与排名</p>
            <p>
              <span style="color: #00FFFF" class="lvclis chishi">参与率</span>
            </p>
          </div>
          <div class="item-content">
            <dv-loading v-if="loa2"></dv-loading>
            <div v-for="(item,i) in participatelist" :key="i" class="v1">
              <p class="p-index"><span class="iconfont icon-dunpai2"><span>{{i+1}}</span></span></p>
              <p class="p-text">{{ item.name }}</p>
              <p class="p-ex"><el-progress :show-text="false" :stroke-width="12" :percentage="parseFloat(item.part_rate)"></el-progress></p>
              <p class="p-num">{{ item.part_rate }}</p>
            </div>
          </div>
        </div>
        <div class="ranking_item">
          <div class="date_item">
            <img class="dateimg" src="../assets/img/xuanzeq.png" alt="">
            <el-date-picker
              v-model="datevalue"

              @change="jianzhong"
              value-format="timestamp"f
              type="daterange"
              range-separator="~"
              start-placeholder="开始"
              end-placeholder="结束"
              :picker-options="pickerOptions"
              :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <img src="../assets/img/radakuang.svg" alt="">
          <div class="item-hide">
            <p style="width: 145px;">减量排名</p>
            <p>
              <span @click="liangde(1)" :style="{'color':(oneclang===1?'#00ffff':'')}"  class="lvclis chishi">下单数</span>
              <span @click="liangde(2)" :style="{'color':(oneclang===2?'#00ffff':'')}" class="lvclis">回收量</span>
              <span @click="liangde(3)" :style="{'color':(oneclang===3?'#00ffff':'')}"  class="lvclis chishi">厨余下单数</span>
              <span @click="liangde(4)" :style="{'color':(oneclang===4?'#00ffff':'')}" class="lvclis">厨余回收量</span>

            </p>
          </div>
          <div class="item-content">
            <dv-loading v-if="loa3"></dv-loading>
            <div v-for="(item,i) in wightrankdata" :key="i" class="v1">
              <p class="p-index"><span class="iconfont icon-dunpai2"><span>{{i+1}}</span></span></p>
              <p class="p-text">{{ item.name }}</p>
              <p class="p-ex"><el-progress :show-text="false" :stroke-width="12" :percentage="parseFloat(item.baifen)"></el-progress></p>

              <p class="p-num" v-if="datas.type===1">{{ parseInt(item.rec_recycling_total_num)}}</p>
              <p class="p-num" v-else-if="datas.type===2">{{ parseInt(item.rec_recycle_total_weight)}}</p>
              <p class="p-num" v-else-if="datas.type===3">{{ parseInt(item.kit_recycling_total_num)}}</p>
              <p class="p-num" v-else-if="datas.type===4">{{ parseInt(item.kit_recycle_total_weight)}}</p>
            </div>
          </div>
        </div>
        <div class="ranking_item">
          <div class="date_item">
            <img class="dateimg" src="../assets/img/xuanzeq.png" alt="">
            <el-date-picker
              v-model="datevalue2"
              size="mini"
              @change="zngzhangpai"
              value-format="timestamp"
              type="daterange"
              range-separator="~"
              start-placeholder="开始"
              end-placeholder="结束"
              :picker-options="pickerOptions"
              :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <img src="../assets/img/radakuang.svg" alt="">
          <div class="item-hide">
            <p>增长排名</p>
            <p>
              <span style="color: #00FFFF" class="lvclis chishi">新增户</span>

            </p>
          </div>
          <div class="item-content">
            <dv-loading v-if="loa4"></dv-loading>
            <div v-for="(item,i) in usergrowthdata" :key="i" class="v1">
              <p class="p-index"><span class="iconfont icon-dunpai2"><span>{{i+1}}</span></span></p>
              <p class="p-text">{{ item.name }}</p>
              <p class="p-ex"><el-progress :show-text="false" :stroke-width="12" :percentage="parseFloat(item.baifen)"></el-progress></p>
              <p class="p-num">{{ item.growth_member_num }}</p>
            </div>
          </div>
        </div>

      </div>

      <!--      图表区域结束-->
    </div>
  </div>
</template>

<script>

import { Getparticipate, Getraknlist, Getusergrowth, Getweightrank } from '@/api/rankingdatails'
import Hiders from '@/components/Hiders'
import Supensing from '@/components/Supensing'
export default {
  data () {
    return {
      chartInstance: null, // 初始化的对象
      allData: null, // 获取的数据
      participatelist: null,
      oneclass: 1,
      datevalue0: '',
      oneclang: 1,
      funname: 'zi',
      datevalue: '',
      datevalue2: '',
      type: 1,
      datas: {
        type: 1,
        start_time: '',
        end_time: ''
      },
      zengdatas: {

        start_time: '',
        end_time: ''
      },
      loa1: false,
      loa2: false,
      loa3: false,
      loa4: false,

      wightrankdata: null, // 减量排名
      usergrowthdata: null, // 增长排名
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  components: {
    Hiders

  },
  mounted () {
    this.getData()
    this.getDatacan()
    this.getWeightrank(this.datas, 1)
    this.getusergrowth()
  },
  methods: {
    // 排序方法
    compare (namse) {
      return (obj1, obj2) => {
        const val1 = obj1[namse] * 1
        const val2 = obj2[namse] * 1
        if (val1 < val2) {
          return 1
        } else if (val1 > val2) {
          return -1
        } else {
          return 0
        }
      }
      // console.log(rr.sort(this.compare('value'))) 调用方式
    },
    // 注册率
    async getData (parameter) {
      if (parameter === 2) {
        // 注册率
        // this.loa1 = true
        // const { data: res } = await Getraknlist({ type: 2 })
        this.loa1 = false
        this.allData = this.allData.sort(this.compare('register_know_rate'))
        this.allData.forEach(item => {
          item.baifen = parseInt(parseFloat(item.register_know_rate) * 100 > 100 ? 100 : parseFloat(item.register_know_rate) * 100) + '%'
        })
      } else {
        // 注册户数
        this.loa1 = true
        const { data: red } = await Getraknlist(parameter)
        this.allData = red.data.sort(this.compare('actual_num_house'))
        this.loa1 = false
        this.allData.forEach(item => {
          item.baifen = parseInt(item.actual_num_house / this.allData[0].actual_num_house * 100) + '%'
        })
      }
    },
    // 参与排名
    async getDatacan (parameter) {
      this.loa2 = true
      const { data: res } = await Getparticipate(parameter)
      this.participatelist = res.data.sort(this.compare('part_rate'))
      this.loa2 = false
      this.participatelist.forEach(item => {
        item.part_rate = parseInt(parseFloat(item.part_rate)) + '%'
      })
    },
    // 减量排名
    async getWeightrank (parameter, shu) {
      this.loading2 = true
      if (shu === 2) {
        const { data: res } = await Getweightrank(parameter)
        this.loading2 = false
        this.wightrankdata = []
        this.wightrankdata = res.data.sort(this.compare('rec_recycle_total_weight'))
        this.wightrankdata.forEach(item => {
          item.baifen = parseInt(item.rec_recycle_total_weight / this.wightrankdata[0].rec_recycle_total_weight * 100) + '%'
        })

        // console.log(this.wightrankdata)
      }
      if (shu === 3) {
        const { data: res } = await Getweightrank(parameter)
        this.loading2 = false
        this.wightrankdata = []
        this.wightrankdata = res.data.sort(this.compare('kit_recycling_total_num'))
        this.wightrankdata.forEach(item => {
          item.baifen = parseInt(item.kit_recycling_total_num / this.wightrankdata[0].kit_recycling_total_num * 100) + '%'
          // console.log(item)
        })

        // console.log(this.wightrankdata)
      }
      if (shu === 4) {
        const { data: res } = await Getweightrank(parameter)
        this.loading2 = false
        this.wightrankdata = []
        this.wightrankdata = res.data.sort(this.compare('kit_recycle_total_weight'))
        this.wightrankdata.forEach(item => {
          item.baifen = parseInt(item.kit_recycle_total_weight / this.wightrankdata[0].kit_recycle_total_weight * 100) + '%'
          // console.log(item)
        })

        // console.log(this.wightrankdata)
      }
      console.log(shu)
      if (shu == 1) {
        // console.log(parameter)
        const { data: res } = await Getweightrank(parameter)
        this.wightrankdata = []
        this.loading2 = false

        this.wightrankdata = res.data.sort(this.compare('rec_recycling_total_num'))
        this.wightrankdata.forEach(item => {
          item.baifen = parseInt(item.rec_recycling_total_num / this.wightrankdata[0].rec_recycling_total_num * 100) + '%'
        })
      }
    },
    // 获取增长排名
    async getusergrowth (parameter) {
      this.loa4 = true
      const { data: res } = await Getusergrowth(parameter)
      this.loa4 = false
      this.usergrowthdata = res.data
      this.usergrowthdata.forEach(item => {
        item.baifen = parseInt(item.growth_member_num / this.usergrowthdata[0].growth_member_num * 100) + '%'
      })
    },
    qiehuan (name) {
      if (name === 1) {
        this.getData()
        this.oneclass = 1
        this.type = 1
      }
      if (name === 2) {
        this.oneclass = 2
        this.type = 2
        this.getData(2)
      }
    },
    liangde (name) {
      this.oneclang = name
      this.datas.type = name
      this.getWeightrank(this.datas, name)
    },
    canpaifun () {
      // type=2&start_time=1621008000&end_time=1621699199

      this.datas.start_time = this.datevalue[0] / 1000
      this.datas.end_time = this.datevalue[1] / 1000
      this.getDatacan(this.datas)
    },
    jianzhong () {
      // type=2&start_time=1621008000&end_time=1621699199

      this.datas.start_time = this.datevalue[0] / 1000
      this.datas.end_time = this.datevalue[1] / 1000
      this.getWeightrank(this.datas, this.datas.type)
    },
    zngzhangpai () {
      this.zengdatas.start_time = this.datevalue2[0] / 1000
      this.zengdatas.end_time = this.datevalue2[1] / 1000
      this.getusergrowth(this.zengdatas)
    }
  }

}
</script>
<style lang="less" >
@import '~@/assets/css/index.less';
#app{

  .com-father{
    .home-cntent{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      .m-map{
        position: absolute;
        z-index: 1;
      }

      .home-echers{
        width: 100%;
        height: 100%;

        position: relative;
        .echers-left , .echers-right{
          width: @show-whith;

          background: #00000073;
          position: absolute;
          top: 0;
          border-top: 3px solid #ffffff4d;
          z-index: 4;
        }

        &:before{
          content: '';
          width: 100%;
          position: absolute;
          bottom: 0;
          z-index: 2;
          box-shadow:0px 37px 178px 30px #052957;
        }
      }
    }
    .community-con{
      padding: 66px 66px 74px 66px;
      background: url("../assets/img/back2.jpg") no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: nowrap;
      overflow: auto;
      .ranking_item{
        position: relative;
        width: 426px;
        height: 733px;
        img{
          position: absolute;
          top: 0;
          left: 0;

        }
        .item-hide{
          display: flex;
          flex-direction: row;
          margin-top: 60px;
          z-index: 99;
          position: relative;
          p{

            margin: 0;
            font-size: 26px;
            font-weight: bold;
            color: #FFFFFF;
            margin-left: 30px;
            .lvclis{
              font-size: 20px;
              font-weight: 500;
              color: #29ABE2FF;
              margin: 0 0px 0 18px;
              &:nth-child(1){
                margin: 0 0px;
              }
            }
          }

        }

        .item-content{
          width: 370px;
          height: 600px;
          overflow: auto;
          z-index: 9;
          position: absolute;
          padding: 27px 0 0 25px;

          .v1{
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 8px;
            p{
              margin: 0;
            }
            .p-index{
              //margin-right: 10px;
              span{
                color: #00FFFFFF;
                font-size: 24px;
                position: relative;
                span{
                  font-size: 16px;
                  color: white;
                  position: absolute;
                  left: 50%;
                  top: 44%;
                  transform: translate(-50%, -50%);
                }
              }

            }

            .p-text{
              color: white;
              font-size: 15px;
              //margin-right: 10px;
              width: 108px;

            }

            .p-ex{
              width: 44%;
              margin-right: 10px;

            }
            .p-num{
              color: white;
              font-size: 15px;
            }
          }

        }
      }
    }
  }

}
.chishi{
  color: #00ffff;
}
.date_item{
  position: absolute;
  z-index: 99;
  right: 1%;
  .el-input__inner{
    width: 180px;
    justify-content: space-between;
    background: rgba(0,0,0,0.03);
    border: 0;
    height: 39px;
    .el-range-input {
      background: transparent;
      font-size: 14px;
      color: #fff;
      width: 50%;
    }
    .el-range-separator{
      line-height: 32px;
      color: #fff;
    }

  }
  .el-range__icon, .el-range__close-icon{
    display: none;
  }
.dateimg{
  width: 120%;
  left: -12% !important;
}
}
.el-picker-panel{

}
.item-content::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); background-color: #F5F5F5; border-radius: 10px;}
.item-content::-webkit-scrollbar{ width: 10px; background-color: transparent;}
.item-content::-webkit-scrollbar-thumb{ border-radius: 10px; background-color: #FFF; background-image: -webkit-linear-gradient(top, #e4f5fc 0%, #bfe8f9 50%, #9fd8ef 51%, #2ab0ed 100%);}
</style>
