import Vue from 'vue'
import { Button, Form, FormItem, Input, Popover, Progress, Autocomplete,DatePicker, Dialog, Loading, Table, TableColumn} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Popover)
Vue.use(Progress)
Vue.use(Autocomplete)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Table)
Vue.use(TableColumn)
