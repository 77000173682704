// export const baseURL = 'http://office.local/api/user'
// export const baseURL = '/api'
// export const baseURL = '/api'
// 本地开发环境
// const devBaseUrl = '/api'
// 线上正式环境
// const proBaseUrl = 'https://s.data.haiwojiamei.com/api'
//    线上测试环境
const proBaseUrl = 'http://dev.datas.haiwojiamei.com/api'

// let url = ''
// process.env.NODE_ENV === 'development' ? url = devBaseUrl : url = proBaseUrl
//
export const baseURL = proBaseUrl
// 高德地图 key
export const MapKey = '403ee29e4534fa96dcba179913d2ad78'
